var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card" }, [
      _vm._m(0),
      _c("div", { staticClass: "card-body row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c(
              "el-table",
              {
                attrs: {
                  data: _vm.tableData,
                  "header-row-class-name": "text-primary",
                },
              },
              [
                _c("el-table-column", { attrs: { type: "index" } }),
                _c("el-table-column", {
                  attrs: { prop: "name", label: "Name" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "job", label: "Job Position" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "salary", label: "Salary" },
                }),
                _c("el-table-column", {
                  attrs: {
                    "class-name": "action-buttons td-actions",
                    align: "right",
                    label: "Actions",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "p-button",
                            {
                              attrs: { type: "info", size: "sm", icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleLike(props.$index, props.row)
                                },
                              },
                            },
                            [_c("i", { staticClass: "fa fa-user" })]
                          ),
                          _c(
                            "p-button",
                            {
                              attrs: { type: "success", size: "sm", icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(props.$index, props.row)
                                },
                              },
                            },
                            [_c("i", { staticClass: "fa fa-edit" })]
                          ),
                          _c(
                            "p-button",
                            {
                              attrs: { type: "danger", size: "sm", icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(
                                    props.$index,
                                    props.row
                                  )
                                },
                              },
                            },
                            [_c("i", { staticClass: "fa fa-times" })]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "card" }, [
      _vm._m(1),
      _c("div", { staticClass: "card-body row table-full-width" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c(
              "el-table",
              {
                staticClass: "table-striped",
                attrs: {
                  "header-row-class-name": "text-primary",
                  data: _vm.tableData,
                },
              },
              [
                _c("el-table-column", { attrs: { type: "index" } }),
                _c("el-table-column", {
                  attrs: { prop: "name", label: "Name" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "job", label: "Job Position" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "salary", align: "center", label: "Salary" },
                }),
                _c("el-table-column", {
                  attrs: { align: "right", label: "Active" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c("p-switch", {
                            model: {
                              value: props.row.active,
                              callback: function ($$v) {
                                _vm.$set(props.row, "active", $$v)
                              },
                              expression: "props.row.active",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "card" }, [
          _vm._m(2),
          _c(
            "div",
            { staticClass: "table-responsive" },
            [
              _c(
                "el-table",
                {
                  staticClass: "table-shopping",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "summary-method": _vm.getSummaries,
                    "show-summary": "",
                    data: _vm.productsTable,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { "min-width": "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c("div", { staticClass: "img-container" }, [
                              _c("img", {
                                attrs: { src: props.row.image, alt: "Agenda" },
                              }),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { "min-width": "220", "class-name": "td-name" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("a", [_vm._v(_vm._s(row.title))]),
                            _c("br"),
                            _c("small", [_vm._v(_vm._s(row.subTitle))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    staticClass: "td-price",
                    attrs: {
                      "class-name": "td-number",
                      "min-width": "80",
                      label: "Price",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c("small", [_vm._v("€")]),
                            _vm._v(
                              " " + _vm._s(props.row.price) + "\n              "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    staticClass: "td-number td-quantity",
                    attrs: {
                      "class-name": "td-number",
                      "min-width": "150",
                      label: "QTY",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _vm._v(
                              "\n                " +
                                _vm._s(props.row.quantity) +
                                "\n                "
                            ),
                            _c(
                              "div",
                              { staticClass: "btn-group btn-group-sm" },
                              [
                                _c(
                                  "p-button",
                                  {
                                    attrs: {
                                      type: "default",
                                      round: "",
                                      outline: "",
                                      size: "sm",
                                    },
                                    on: {
                                      click: function ($event) {
                                        props.row.quantity > 0
                                          ? props.row.quantity--
                                          : 0
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "nc-icon nc-simple-delete",
                                    }),
                                  ]
                                ),
                                _c(
                                  "p-button",
                                  {
                                    attrs: {
                                      type: "default",
                                      round: "",
                                      outline: "",
                                      size: "sm",
                                    },
                                    on: {
                                      click: function ($event) {
                                        props.row.quantity++
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "nc-icon nc-simple-add",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "class-name": "td-number",
                      label: "Total",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c("strong", [
                              _c("small", [_vm._v("€")]),
                              _vm._v(
                                " " +
                                  _vm._s(props.row.quantity * props.row.price) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h5", { staticClass: "card-title" }, [_vm._v("Table with Links")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h5", { staticClass: "card-title" }, [_vm._v("Table with Switches")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h5", { staticClass: "card-title" }, [_vm._v("Shopping Cart Table")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }